import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ItemPreview from '../components/itemPreview/item-preview'

class NeighborhoodsPage extends React.Component {
  render() {
    const neighborhoods = get(this, 'props.data.allContentfulNeighborhood.nodes')
    const allCities = get(this, 'props.data.allContentfulCity.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo title="Neighborhoods" />
        <Hero title="Neighborhoods" />
    
        <ItemPreview items={neighborhoods} slugName="neighborhood" />

        {allCities.map((city) => {
            const filteredNeighborhoods = neighborhoods.filter(neighborhood =>
              neighborhood.cities.map(city => 
                city.name
                ) === city.name
              );
            return (
              <div>
              <h2>{city.name}</h2>
              
              <h3>Neighborhoods</h3>
              {filteredNeighborhoods.map((neighborhood) => {  
                return (   
                <div>{neighborhood.name}</div>
                )
              })}
              
              </div>      
              )
          })}
      </Layout>
    )
  }
}

export default NeighborhoodsPage

export const pageQuery = graphql`
  query NeighborhoodsPageQuery {
    allContentfulCity {
      nodes {
        name
        slug
      }
    }
    allContentfulNeighborhood(sort: { fields: [name], order: DESC })  {
      nodes {
        name
        slug
        cities {
          name
        }
      }
    }
  }
`
